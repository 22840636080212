import React from "react";
import Header from "../../../../components/header/header";
import Content from "../../../../components/main/content/content";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/footer";

import { graphql } from "gatsby";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  return (
    <div className="layout">
      <Header
        category={data?.cms?.categories}
        domain={data?.cms?.domain}
        allPosts={allPosts}
        engine={options}
      />
      <div className="container is-max-widescreen">
        <div className="columns m-0">
          <Content story={data?.cms?.page} />
          <Sidebar recent_story={data?.cms?.recent_story} />
        </div>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"steven@finalscope.com"} />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query pages($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "12") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "12") {
        id
        image
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "12") {
        id
        image
        pub_date
        slug
        sub_title
        title
        categories {
          name
        }
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: "12") {
        title
        is_feature_post
        image
        slug
        content: description
        pub_date
        categories {
          name
        }
      }
      CMS_Story: CMS_Page(id: $slug) {
        content
        id
        slug
        title
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
