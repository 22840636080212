import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { isMobile, browserName } from "react-device-detect";

const Sidebar = ({ recent_story, banner }) => {
  let filteredData = recent_story?.filter(function (el) {
    return el?.image?.length > 0;
  });

  const recent_articles = [];
  for (let index = 0; index < 7; index++) {
    const element = filteredData[index];
    recent_articles.push(element);
  }

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    let temp = str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    return temp.replaceAll("idc-ads-media-production.s3.ap-south-1.amazonaws.com", `cdn.trickybrick.com`);
  }

  const renderBanner = () => (
    <>
      <div className="side-banner-container">
        <Link to={banner.png_8_url} target="_blank">
          <img src={banner.png_8} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.png_9_url} target="_blank">
          <img src={banner.png_9} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.png_6_url} target="_blank">
          <img src={banner.png_6} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.gif_1_url} target="_blank">
          <img src={banner.gif_1} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.png_2_url} target="_blank">
          <img src={banner.png_2} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.gif_3_url} target="_blank">
          <img src={banner.gif_3} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.gif_4_url} target="_blank">
          <img src={banner.gif_4} alt={"banner"} />
        </Link>
      </div>
      {/* <div className="side-banner-container">
        <Link to={banner.url} target="_blank">
          <img src={banner.banner2} alt={"banner"} />
        </Link>
      </div>
      <div className="side-banner-container">
        <Link to={banner.url}>
          <img src={banner.banner1} alt={"banner"} />
        </Link>
      </div> */}
      {/* <div className="side-banner-container">
        <Link to={banner.url}>
          <img src={banner.gif} alt={"banner"} />
        </Link>
      </div> */}
    </>
  );

  return (
    <aside className="sidebar">
      {banner && renderBanner()}
      <div className="list">
        {/* {!isMobile && (
          <div>
            <div class="ad-label">Advertisement</div>
            <div id="desktop_300x250">
              <div id="sidebar_depth0" class="adSlot" data-placement="desktop_300x250_1"></div>
            </div>
            <div
              class="fbPage"
              data-href="https://www.facebook.com/illumeably"
              data-width="300"
              data-hide-cover="false"
              data-show-facepile="false"
            ></div>
            <div class="ad-label">Advertisement</div>
            <div id="desktop_300x250">
              <div id="sidebar_depth1" class="adSlot" data-placement="desktop_300x250_2"></div>
            </div>
          </div>
        )} */}

        <div className="list-head">
          <span>Recent Featured Articles</span>
        </div>
        {recent_articles.map((element) => {
          return (
            <Link to={`/${element?.slug}/`}>
              <div className="list-item">
                <div className="list-item-image">
                  <figure className="image w70h70">
                    {element?.image.includes("idc-ads-media") ? <img src={addStr(element?.image, 65, "200x200_")} /> : <img src={addStr(element?.image, 37, "200x200_")} />}
                  </figure>
                </div>

                <div className="list-item-content">
                  <div className="list-item-title">{element?.title}</div>
                  <div className="list-item-description">{element?.categories[0]?.name}</div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </aside>
  );
};
export default Sidebar;
